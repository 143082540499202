import { Container, Stack } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import { useAppDispatch } from "../../core/redux/hooks";
import { StatByJobTypeObject } from "../../core/types/stats.types";
import {
  CompanyStatsDatesChart,
  CompanyStatsCountyChart,
  CompanyStatsJobClicksByCountyChart,
  ApexChart,
} from "../../sections/@dashboard/company/CompanyStatsChart";
import { request } from "../../utils/Axios";

export interface CompanyStaticsType {
  dates: { _id: string; count: number }[];
  events: { _id: string; count: number }[];
  sources: { _id: string; count: number }[];
  users: { _id: string; count: number }[];
  applicationsByCountry: { _id: string; country: string; count: number }[];
  applicationsForSpecificCategoryByCountry: StatByJobTypeObject[];
}

export default function AnalyticsSlug() {
  const { slug } = useParams();
  const [companyStats, setCompanyStats] = useState<CompanyStaticsType>();

  // -------------------Redux------------------------
  const dispatch = useAppDispatch();
  // ------------------------------------------------

  const handleFetchCompanyJobs = useCallback(async () => {
    const { data } = await request.get(`api/v1/company/slug/${slug}`);
    const res = await request.get(`api/v1/company/stats/${data.body._id}`);
    setCompanyStats(res.data.body);
  }, [slug]);

  useEffect(() => {
    if (slug) {
      handleFetchCompanyJobs();
    }
  }, [slug, dispatch, handleFetchCompanyJobs]);

  return (
    <Page title="Job Center">
      <Container maxWidth="xl">
        {/* <ProductList /> */}
        {companyStats?.dates ? (
          <Stack spacing={3} mb={3}>
            <CompanyStatsDatesChart
              tagName="Events by date"
              title="Events by date"
              stats={companyStats?.dates}
            />
            <CompanyStatsCountyChart
              title="User Events by Country"
              stats={companyStats?.users}
            />

            <CompanyStatsJobClicksByCountyChart
              title="Job Clicks by Country"
              stats={companyStats?.applicationsByCountry}
            />

            <ApexChart
              title="Clicks on specific group by Country"
              stats={companyStats?.applicationsForSpecificCategoryByCountry}
            />
          </Stack>
        ) : null}
      </Container>
    </Page>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";
import {
  DashboardStatsP1,
  DashboardStatsP2,
  JobByCompany,
  JobCategoriesStats,
  LocationStats,
} from "../../../types/stats.types";

export const fetchDashboard1Stats = createAsyncThunk(
  "stats/fetchDashboard1Stats",
  async (): Promise<{ body: DashboardStatsP1 }> => {
    const { data } = await request.get("/api/v1/stats/dashboard/admin");
    return data;
  }
);

export const fetchDashboard2Stats = createAsyncThunk(
  "stats/fetchDashboard2Stats",
  async (): Promise<{ body: DashboardStatsP2 }> => {
    const res = await request.get("/api/v1/stats/daily-user-change");
    return res.data;
  }
);

export const fetchYearlyUserStats = createAsyncThunk(
  "stats/fetchYearlyUserStats",
  async (): Promise<{
    body: Array<{ date: string; logins: number; signups: number; _id: string }>;
  }> => {
    const res = await request.get("/api/v1/stats/user-signups");
    return res.data;
  }
);

export const fetchYearlyUserStatsByCountry = createAsyncThunk(
  "stats/fetchYearlyUserStatsByCountry",
  async (): Promise<{
    body: Array<{
      date: string;
      day: string;
      month: string;
      year: string;
      countryAnalytics: { country: string; count: number }[];
      _id: string;
    }>;
  }> => {
    const res = await request.get("/api/v1/stats/signups-by-country");
    return res.data;
  }
);

export const fetchUserLocationStats = createAsyncThunk(
  "stats/fetchUserLocationStats",
  async (timePeriod: string): Promise<{ body: LocationStats }> => {
    // const allData = "/api/v1/stats/user-location";
    const periodData = `/api/v1/stats/user-location?timePeriod=${timePeriod}`;
    const res = await request.get(periodData);
    return res.data;
  }
);

export const fetchUserApplyStats = createAsyncThunk(
  "stats/fetchUserApplyStats",
  async (): Promise<{
    body: {
      _id: string;
      country: string;
      data: { count: number; date: string }[];
    }[];
  }> => {
    const res = await request.get("/api/v1/stats/user-apply");
    return res.data;
  }
);

export const fetchApplyByJobTypeStats = createAsyncThunk(
  "stats/fetchApplyByJobTypeStats",
  async (
    timePeriod?: string
  ): Promise<{
    body: { _id: string; jobtype: string; count: number }[];
  }> => {
    const allData = "/api/v1/stats/job-type-apply";
    const periodData = `/api/v1/stats/job-type-apply?timePeriod=${timePeriod}`;
    const res = await request.get(timePeriod ? periodData : allData);
    return res.data;
  }
);

export const fetchViewStatsByCountryAndJobType = createAsyncThunk(
  "stats/fetchViewStatsByCountryAndJobType",
  async (req: {timePeriod?:string, category?:string}): Promise<{
    body: { _id: string; jobType: string; count: number }[];
  }> => {
    const res = await request.get(req.timePeriod ? `/api/v1/stats/job-type-view-country?timePeriod=${req.timePeriod}&category=${req.category}` : "/api/v1/stats/job-type-view-country");
    return res.data;
  }
);

export const fetchJobCategoriesStats = createAsyncThunk(
  "stats/fetchJobCategoriesStats",
  async (): Promise<{
    body: Array<JobCategoriesStats>;
  }> => {
    const res = await request.get("/api/v1/stats/jobs-category-stats");
    return res.data;
  }
);

export const fetchJobByCompaniesStats = createAsyncThunk(
  "stats/fetchJobByCompaniesStats",
  async (): Promise<{
    body: Array<JobByCompany>;
  }> => {
    const res = await request.get("/api/v1/stats/company-jobs-stats");
    return res.data;
  }
);

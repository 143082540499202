// material
import { Grid } from "@mui/material";
import JobsCard from "./JobCard";
import { JobData } from "../../../core/types/job.types";

// ----------------------------------------------------------------------

export default function JobList({
  jobs,
  newIndicator,
  ...other
}: {
  jobs: JobData[];
  newIndicator?: boolean;
  other?: any;
}) {
  return (
    <Grid container spacing={2} {...other}>
      {jobs?.map((i) => (
        <Grid key={i._id} item xs={12} sm={6} md={4} lg={4}>
          <JobsCard newIndicator={newIndicator || false} {...i} />
        </Grid>
      ))}
    </Grid>
  );
}

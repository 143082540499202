import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";
import { User } from "../../../types/auth.types";

export const login = createAsyncThunk(
  "auth/login",
  async (payload: { email: string; password: string }): Promise<{ access_token: string; body: User }> => {
    const res = await request.post("/api/v1/auth/login", payload);
    return res.data;
  }
);

export const getAdminData = createAsyncThunk(
  "auth/getAdminData",
  async (): Promise<{ access_token: string; body: User }> => {
    const res = await request.get("/api/v1/auth/user-data");

    return res.data;
  }
);
